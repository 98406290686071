import React, { useEffect, useRef, useState } from "react";
import { appFetch } from "../../../helpers/app-fetch";
import { PlanBoxStyled, Ribbon } from "../../home/pricing-section";
import {
  ButtonPrimary,
  ButtonSecondary
} from "../../../components/buttons/button";
import styled from "styled-components";
import { ICredentials, useUserContext } from "../../../context/user-context";

import CelebrateImage from "../../../assets/celebrate-2.webp";
import WaitImage from "../../../assets/wait.webp";
import Spinner from "../../../assets/blue-on-white-spinner.gif";

import { Link } from "react-router-dom";
import { frontEnd } from "../../../config/links";
import TableComponent from "./table";
import useLocalStorage from "../../../helpers/use-local-stroage";
import Modal from "./ovarlay";
import { useOutsideClick } from "../../../helpers/use-outside-click";

const Message = ({ message }) => (
  <section style={{ margin: "20px 20px" }}>
    <StyledMessage>{message}</StyledMessage>
  </section>
);

const Purchase = () => {
  const [message, setMessage] = useState("");
  const { userState, setStateUserState } = useUserContext();
  const [redirectToPurchase, setRedirectToPurchase] = useLocalStorage(
    "purchaseRedirect",
    false
  );

  useEffect(() => {
    if (redirectToPurchase) {
      setRedirectToPurchase(false);
    }
  }, []);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      //@ts-ignore
      gtag("event", "conversion", {
        send_to: "AW-11358620077/zywSCO_HufUZEK2Tm6gq",
        transaction_id: ""
      });

      setMessage("success");
    }

    // if (query.get("canceled")) {
    //   setMessage(
    //     "Order canceled -- continue to shop around and checkout when you're ready."
    //   );
    // }
  }, []);

  useEffect(() => {
    let getUser = async () => {
      let user: ICredentials;
      const token = localStorage.getItem("token");

      if (!token) return null;

      console.log("here");
      if (message != "success") {
        return;
      }

      let response = await appFetch("/api/auth/validate", {
        method: "GET"
      });

      //this will be setting local storage to current user instead
      //LogRocket.identify(response.value);
      localStorage.setItem("user", JSON.stringify({ email: response.value }));
      //todo configure dynamic routing
      //history.push(frontEnd.dashboardHome);

      if (response.value.email !== userState.attributes.email)
        setStateUserState({
          attributes: {
            email: response.value.email,
            firstName: response.value.firstName,
            lastName: response.value.lastName,
            roles: response.value?.roles || [],
            registered: response.value?.registered,
            registeredUntil: response.value?.registeredUntil,
            emailConfirmed: response.value?.emailConfirmed,
            referrer: userState?.attributes?.referrer ?? null
          }
        });
    };
    getUser();
    const intervalId = setInterval(getUser, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const redirectToStripe = async ({ duration, exam }) => {
    appFetch(
      "/api/auth/payment-redirect",
      {
        method: "POST"
      },
      {
        exam: exam,
        Duration: duration
      }
    ).then(data => {
      window.location.href = data.url;
    });
  };

  const [showModal, setShowModal] = useState(false);

  const node = useRef<any>();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useOutsideClick(showModal, node, () => {
    setShowModal(s => false);
  });

  if (message == "success" && userState.attributes.registered) {
    return (
      <Container
        style={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          flexWrap: "nowrap"
        }}
      >
        <picture style={{ width: 450 }}>
          <source type="image/webp" srcSet={CelebrateImage} />
          <img
            src={CelebrateImage}
            alt="Cartoon image of a person using a laptop"
          />
        </picture>
        <div style={{ width: "100%" }}></div>
        <Message
          message={
            "Order placed! You will receive an email confirmation shortly."
          }
        />
        <Link to={frontEnd.dashboardHome}>
          <ButtonPrimary text="start studying"> </ButtonPrimary>
        </Link>
      </Container>
    );
  } else if (message == "success") {
    return (
      <Container
        style={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          flexWrap: "nowrap"
        }}
      >
        <picture style={{ width: 450 }}>
          <source type="image/webp" srcSet={WaitImage} />
          <img src={WaitImage} alt="Cartoon image of a person using a laptop" />
        </picture>
        <Message
          message={"Order placed! we're just setting up your subscription now"}
        />
        <img
          src={Spinner}
          style={{ width: 45, height: 45, marginLeft: "10px", marginTop: 20 }}
          alt="spinner"
        />
        <Link to={frontEnd.dashboardHome}>
          {/* <ButtonPrimary text="start studying"> </ButtonPrimary> */}
        </Link>
      </Container>
    );
  }

  if (message) {
    return (
      <Container>
        <Message message={message} />
      </Container>
    );
  }

  return (
    <Container>
      <div
        style={{
          fontSize: 20,
          fontWeight: 600,
          width: "100%",
          textAlign: "center"
        }}
      >
        Plan Options:
      </div>
      {/* <div style={{ height: 130, marginTop: 30 }}>
        <Title>Upgrade your subscription to unlock: </Title>
        <FeaturesList>
          <FeatureItem>New exam modes</FeatureItem>
          <FeatureItem>Over 2000 new questions</FeatureItem>
          <FeatureItem>Detailed explanations for every question</FeatureItem>
        </FeaturesList>
      </div> */}
      <PlanBoxesFlex>
        <PlanBoxStyled HideZ={false}>
          <h3> 1 month </h3>
          <h4>$90</h4>
          <ButtonPrimary
            text="Join"
            wide
            onClick={() => redirectToStripe({ exam: "GSSE", duration: "1m" })}
          ></ButtonPrimary>
        </PlanBoxStyled>
        <PlanBoxStyled HideZ={false}>
          <Ribbon>
            <span>Recommended</span>
          </Ribbon>
          <h3> 3 months </h3>
          <h4>$150</h4>
          <ButtonPrimary
            text="Join"
            wide
            onClick={() => redirectToStripe({ exam: "GSSE", duration: "3m" })}
          ></ButtonPrimary>
        </PlanBoxStyled>
        <PlanBoxStyled HideZ={false}>
          <h3> 6 months </h3>
          <h4>$220</h4>
          <ButtonPrimary
            text="Join"
            wide
            onClick={() => redirectToStripe({ exam: "GSSE", duration: "6m" })}
          ></ButtonPrimary>
        </PlanBoxStyled>
      </PlanBoxesFlex>
      <div
        style={{
          fontSize: 20,
          fontWeight: 600,
          width: "100%",
          textAlign: "center",
          padding: 20
        }}
      >
        Premium Subscription Features:
      </div>
      {/* <div style={{width: "100%"}}> */}
      <TableComponent />
      {/* {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )} */}

      {/* </div> */}

      <div style={{ width: "100%" }}></div>
      <Link to={frontEnd.dashboardHome}>
        <ButtonSecondary
          text="Trial free version"
          style={{ marginTop: 20 }}
        ></ButtonSecondary>
      </Link>

      <div style={{ width: "100%" }}></div>

      <p style={{ textAlign: "left", marginTop: 20, fontSize: 14 }}>
        * email support@get-thru.co.nz if you didn't pass you exam and receive a
        full refund or a free subscription of the same duration.
      </p>
      {/* <button onClick={toggleModal}>Open Modal</button> */}
      {/* <div ref={node}>
      <Modal show={showModal} onClose={toggleModal} node={node} >

        <h1>Hello, I'm a Modal!</h1>
        <p>Click the X button to close me.</p>
      </Modal>
      </div> */}
    </Container>
  );
};

const StyledMessage = styled.p`
  font-size: 16px;
`;

const FeatureItem = styled.li`
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &:before {
    content: "✔";
    color: green;
    margin-right: 10px;
  }
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
`;

const Description = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  width: 100%;
  //   max-width: 600px;
  width: 100%;
  height: 100%;
  margin: 50;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: scroll;
`;

const PlanBoxesFlex = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  /* justify-content: center; */
  margin-top: 40px;
`;

const Heading = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const PlanCard = styled.div`
  border: 2px dashed #ccc;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 10px;
`;

const PlanButton = styled.button`
  background-color: #0000ff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0000cc;
  }
`;

export default Purchase;
