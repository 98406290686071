import React from "react";

import styled from "styled-components";

import { SV, MQ } from "../../styles/styles-variables";
import Logo from "../../components/logo";
import {
  FacebookIcon,
  InstagramIcon
} from "../../components/styled-components/icons";
import { Link } from "react-router-dom";
import { frontEnd } from "../../config/links";

const Footer = () => (
  <>
    <Bar />
    <FlexDiv>
      <Logo />
      <Flex>
        <div>
          <a href="https://blogs.get-thru.co.nz/">Blogs</a>
          <a href="#experience">Features</a>
        </div>
        <div>
          <a href="/#plan-section">Pricing</a>
          {/* /todo add contact and privacy pages */}
          {/* <a>Contact us</a>
          <a>Privacy</a> */}
        </div>
      </Flex>

      {/* <div style={{ flex: 1 }} /> */}
      <Flex>
        <FacebookIcon />
        <InstagramIcon />
      </Flex>
    </FlexDiv>
    <div>
      {" "}
      © Medical Education Technologies Group Ltd 2016, 62 Alexandata Road,
      Hataitai, Wellington 6021, New Zealand, +64277729830{" "}
    </div>
    <Link style={{ display: "none" }} to={frontEnd.confirmEmail}>
      confirm-email
    </Link>
    <Link style={{ display: "none" }} to={frontEnd.resetPassword}>
      resend-email
    </Link>
  </>
);
//

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  svg {
    margin: 10px 10px;
  }
  a {
    font-weight: 600;
    font-size: 14px;
    margin: 10px 10px;
  }
  * {
    cursor: pointer;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  @media ${MQ.tablet} {
    justify-content: space-between;
  }
  margin: 0 20px 20px 20px;
`;

const Bar = styled.div`
  margin-top: 120px;
  width: 100%;
  border-top: 1px solid ${SV.colors.darkMain1};
`;

export default Footer;
