import { useEffect } from "react";
import { history } from "../App";
import { useImmer } from "../helpers/use-immer";
import { restUri as baseUrl } from "../uris";
import { frontEnd } from "../config/links";

interface IUseAppFetch {
  loading: boolean;
  error?: any;
  data?: any;
}

interface IAppFetchInputs {
  url: string;
  inputOptions?: any;
  data?: any;
}

const initialState = () => ({
  loading: false
});

//todo finish useLAzy
const useLazyAppFetch = () => {
  const [state, setState] = useImmer<IAppFetchInputs | undefined>(undefined);

  const [fetchState, setFetchState] = useImmer<IUseAppFetch>(initialState());

  const { data, loading, error } = useAppFetch(
    state?.url || "",
    state?.inputOptions,
    state?.data
  );

  const LazyFunc = (url: string, inputOptions?: any, data?: any) =>
    setState(ds => {
      return { url, inputOptions, data };
    });

  return { LazyFunc, data, loading, error };
};

const useAppFetch = (url: string, inputOptions?: any, data?: any) => {
  const [state, setState] = useImmer<IUseAppFetch>(initialState());

  useEffect(() => {
    const callAppFetch = async () => {
      if (url) {
        setState(ds => {
          ds.loading = true;
        });
        try {
          const response = await appFetch(url, inputOptions, data);
          setState(ds => {
            ds.loading = false;
            ds.data = response;
            ds.error = undefined;
          });
        } catch (error) {
          // debugger;
          setState(ds => {
            ds.loading = false;
            ds.error = error;
            ds.data = undefined;
          });
        }
      }
    };
    callAppFetch();
  }, [url, inputOptions, data]);
  return state;
};

async function appFetch(url: string, inputOptions?: any, data?: any) {
  const options = { ...inputOptions };
  options.headers = { ...(inputOptions?.headers || ({} as any)) };
  options.headers["Content-Type"] = "application/json";
  options.headers["accepts"] = "application/json";
  if(localStorage.getItem("token")){
    options.headers["Authorization"] = `bearer ${localStorage.getItem("token")}`;
  }

  //console.log("token", localStorage.getItem("token"))
  if (data) options.body = JSON.stringify({ ...data });

  try {
    const result = await fetch(baseUrl + url, options);

    if (result.status >= 200 && result.status <= 300) {
      if (result.body) {
        return await result.json();
      }
    } else {
      if (result.status == 401 || result.status == 403) {
        history.push(frontEnd.login);
      }
      const errorMessage = await result.text();
      if (typeof errorMessage == "string") {
        throw errorMessage;
      } else {
        //
        console.log(errorMessage);
        throw JSON.stringify(errorMessage || "");
      }
    }
  } catch (e) {
    throw e;
  }
  return null;
}

export { appFetch, useAppFetch, useLazyAppFetch };
